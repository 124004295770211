// 路由meta的title项 必须为 该多语言对应字段名
import warehouseDataIcon from '@/assets/images/sidebar-icon/warehouse-data-icon.svg'
import warehousingManagementIcon from '@/assets/images/sidebar-icon/warehousing-management-icon.svg'
import abnormalDataIcon from '@/assets/images/sidebar-icon/abnormal-data-icon.svg'
import baseSettingIcon from '@/assets/images/sidebar-icon/base-setting-icon.svg'
import outWarehouseManagementIcon from '@/assets/images/sidebar-icon/out-warehouse-management-icon.svg'
import orderManagementIcon from '@/assets/images/sidebar-icon/order-management-icon.svg'
import palletManagementIcon from '@/assets/images/sidebar-icon/pallet-management-icon.svg'
import deliveryReservationIcon from "@/assets/images/sidebar-icon/delivery-reservation-icon.svg"
export default [
    {
        path: '/operateCenter',
        name: 'operateCenter',
        component: () => import('@/views/operate-center'),
        children: [
            {
                path: '/operateCenterIndex', // 仓库数据
                name: 'warehouseData',
                component: () => import('@/views/operate-center/warehouse-data'),
                redirect: '/operateCenter/warehouseData/index',
                meta: {
                    title: 'warehouse_data',
                    icon: warehouseDataIcon,
                    isMulti: false,
                },
                children: [
                    {
                        path: '/operateCenter/warehouseData/index', // 仓库数据
                        name: 'warehouseDataIndex',
                        component: () => import('@/views/operate-center/warehouse-data/warehouse-data-index'),
                        meta: {
                            title: 'warehouse_data',
                            isShowing: false
                        },
                    },
                    {
                        path: '/operateCenter/warehouseData/subareaData',
                        name: 'warehouseSubareaData',
                        component: () => import('@/views/operate-center/warehouse-data/warehouse-subarea-data'),
                        meta: {
                            title: 'classification_data',
                            isShowing: false
                        },
                    },
                    {
                        path: '/operateCenter/warehouseData/subareaDetail',
                        name: 'warehouseSubareaDetail',
                        component: () => import('@/views/operate-center/warehouse-data/warehouse-subarea-detail'),
                        meta: {
                            title: 'classification_details',
                            isShowing: false
                        },
                    },
                    {
                        path: '/operateCenter/warehouseData/palletDetail',
                        name: 'warehousePalletDetail',
                        component: () => import('@/views/operate-center/warehouse-data/warehouse-pallet-detail'),
                        meta: {
                            title: 'pela_details',
                            isShowing: false
                        },
                    },
                ]
            },
            // {
            //     path: '/operateCenter/warehousingManagement',  // 入库管理
            //     name: 'warehousingManagement',
            //     component: () => import('@/views/operate-center/warehousing-management'),
            //     redirect: '/operateCenter/warehousingManagement/index',
            //     meta: {
            //         title: 'cargo_entry_management',
            //         icon: warehousingManagementIcon,
            //         isMulti: false,
            //     },
            //     children: [
            //         {
            //             path: '/operateCenter/warehousingManagement/index',
            //             name: 'warehousingManagementIndex',
            //             component: () => import('@/views/operate-center/warehousing-management/warehousing-management-index'),
            //             meta: {
            //                 title: 'cargo_entry_management',
            //                 isShowing: false
            //             }
            //         },
            //         {
            //             path: '/operateCenter/warehousingManagement/detail',
            //             name: 'warehousingManagementDetail',
            //             component: () => import('@/views/operate-center/warehousing-management/warehousing-management-detail'),
            //             meta: {
            //                 title: 'cargo_entry_details',
            //                 isShowing: false
            //             }
            //         },
            //         {
            //             path: '/operateCenter/warehousingManagement/waybillDetail',
            //             name: 'warehousingWaybillDetail',
            //             component: () => import('@/views/operate-center/warehousing-management/warehousing-management-waybillDetail'),
            //             meta: {
            //                 title: 'Shipment_details',
            //                 isShowing: false
            //             }
            //         },
            //     ]
            // },
            // {
            //     path: '/operateCenter/outWarehouseManagement', // 出库管理
            //     name: 'outWarehouseManagement',
            //     component: () => import('@/views/operate-center/out-warehouse-management'),
            //     redirect: '/operateCenter/outWarehouseManagement/index',
            //     meta: {
            //         title: 'load_output_administration',
            //         icon: outWarehouseManagementIcon,
            //         isMulti: false,
            //     },
            //     children: [
            //         {
            //             path: '/operateCenter/outWarehouseManagement/index',
            //             name: 'outWarehouseManagementIndex',
            //             component: () => import('@/views/operate-center/out-warehouse-management/out-warehouse-management-index'),
            //             meta: {
            //                 title: 'load_output_administration',
            //                 isShowing: false
            //             }
            //         },
            //         {
            //             path: '/operateCenter/outWarehouseManagement/detail', // 出库详情
            //             name: 'outWarehouseDetail',
            //             component: () => import('@/views/operate-center/out-warehouse-management/out-warehouse-detail'),
            //             meta: {
            //                 title: 'load_output_details',
            //                 isShowing: false
            //             },
            //         },
            //         {
            //             path: '/operateCenter/outWarehouseManagement/waybillDetail', // 运单详情
            //             name: 'outWarehouseWaybillDetail',
            //             component: () => import('@/views/operate-center/out-warehouse-management/out-warehouse-waybill-detail'),
            //             meta: {
            //                 title: 'Shipment_details',
            //                 isShowing: false
            //             },
            //         },
            //     ]
            // },
            // {
            //     path: '/operateCenter/orderManagement',  // 订单管理
            //     name: 'orderManagement',
            //     component: () => import('@/views/operate-center/order-management'),
            //     redirect: '/operateCenter/orderManagement/index',
            //     meta: {
            //         title: 'order_administration',
            //         icon: orderManagementIcon,
            //         isMulti: false,
            //     },
            //     children: [
            //         {
            //             path: '/operateCenter/orderManagement/index',
            //             name: 'orderManagementIndex',
            //             component: () => import('@/views/operate-center/order-management/order-management-index'),
            //             meta: {
            //                 title: 'order_administration',
            //                 isShowing: false
            //             }
            //         },
            //         {
            //             path: '/operateCenter/orderManagement/orderDetail', // 订单详情
            //             name: 'orderDetail',
            //             component: () => import('@/views/operate-center/order-management/order-detail'),
            //             meta: {
            //                 title: 'order_details',
            //                 isShowing: false
            //             },
            //         },
            //     ]
            // },
            {
                path: '/operateCenter/shipmentno',  // 订单管理
                name: 'shipmentno',
                component: () => import('@/views/operate-center/shipment-no'),
                redirect: '/operateCenter/shipmentno/index',
                meta: {
                    title: 'shipment_administration',
                    icon: orderManagementIcon,
                    isMulti: false,
                },
                children: [
                    {
                        path: '/operateCenter/shipmentno/index',
                        name: 'shipmentnoIndex',
                        component: () => import('@/views/operate-center/shipment-no/shipment-no-index'),
                        meta: {
                            title: 'shipment_administration',
                            isShowing: false
                        }
                    },
                ]
            },
            {
                path: '/operateCenter/containerManagement', // 容器管理
                name: 'containerManagement',
                component: () => import('@/views/operate-center/container-management'),
                redirect: '/operateCenter/containerManagement/index',
                meta: {
                    title: 'container_administration',
                    icon: palletManagementIcon,
                    isMulti: false,
                },
                children: [
                    {
                        path: '/operateCenter/containerManagement/index',
                        name: 'containerManagementIndex',
                        component: () => import('@/views/operate-center/container-management/container-management-index'),
                        meta: {
                            title: 'container_administration',
                            isShowing: false
                        }
                    },
                    {
                        path: '/operateCenter/containerManagement/detail',
                        name: 'containerManagementDetailx',
                        component: () => import('@/views/operate-center/container-management/container-detail'),
                        meta: {
                            title: 'container_details', // 容器详情
                            isShowing: false
                        }
                    },
                ]
            },
            // 美客多预约
            // {
            //     name: "deliveryReservation",
            //     path: "/operateCenter/deliveryReservation",
            //     component: () => import("@/views/operate-center/delivery-reservation"),
            //     redirect: "/operateCenter/deliveryReservation/deliveryReservationStatus",
            //     meta: {
            //         title: 'Mercado_Libre_Reservation', // 美客多预约
            //         icon: deliveryReservationIcon,
            //         isMulti: true,
            //         isShowing: true
            //     },
            //     children: [
            //         {
            //             name: "deliveryReservationStatus",
            //             path: "/operateCenter/deliveryReservation/deliveryReservationStatus",
            //             component: () => import("@/views/operate-center/delivery-reservation/reservation-status"),
            //             meta: {
            //                 title: "Reservation_status", // 预约情况
            //                 isShowing: true,
            //             }
            //         },
            //         {
            //             name: "deliveryReservationSearch",
            //             path: "/operateCenter/deliveryReservation/deliveryReservationSearch",
            //             component: () => import("@/views/operate-center/delivery-reservation/shipmentNo-search"),
            //             meta: {
            //                 title: "Check_the_shipment_number", // 货件号查询
            //                 isShowing: true
            //             }
            //         }
            //     ]
            // },
            // {
            //     path: '/operateCenter/abnormalData', // 异常数据
            //     name: 'abnormalData',
            //     component: () => import('@/views/operate-center/abnormal-data'),
            //     redirect: '/operateCenter/abnormalData/warehousingAbnormal',
            //     meta: {
            //         title: 'Abnormal_data',
            //         icon: abnormalDataIcon,
            //         isMulti: true,
            //     },
            //     children: [
            //         {
            //             path: '/operateCenter/abnormalData/warehousingAbnormal', // 入仓异常
            //             name: 'warehousingAbnormal',
            //             component: () => import('@/views/operate-center/abnormal-data/warehousing-abnormal'),
            //             meta: {
            //                 title: 'abnormal_load_input_situation',
            //                 isShowing: true
            //             }
            //         },
            //         {
            //             path: '/operateCenter/abnormalData/warehousingAbnormalDetail', // 异常详情
            //             name: 'warehousingAbnormalDetail',
            //             component: () => import('@/views/operate-center/abnormal-data/warehousing-abnormal-detail'),
            //             meta: {
            //                 title: 'abnormal_situation_details',
            //                 isShowing: false,
            //                 activeTitle: 'warehousingAbnormal'
            //             }
            //         },
            //         {
            //             path: '/operateCenter/abnormalData/outWarehouseAbnormal', // 出仓异常
            //             name: 'outWarehouseAbnormal',
            //             component: () => import('@/views/operate-center/abnormal-data/out-warehouse-abnormal'),
            //             meta: {
            //                 title: 'load_output_abnormal_situation',
            //                 isShowing: true
            //             }
            //         },
            //         {
            //             path: '/operateCenter/abnormalData/outWarehouseAbnormalDetail', // 异常详情
            //             name: 'outWarehouseAbnormalDetail',
            //             component: () => import('@/views/operate-center/abnormal-data/out-warehouse-abnormal-detail'),
            //             meta: {
            //                 title: 'abnormal_situation_details',
            //                 isShowing: false,
            //                 activeTitle: 'outWarehouseAbnormal'
            //             }
            //         },
            //         {
            //             path: '/operateCenter/abnormalData/ownerlessCargo', // 无主认领货物
            //             name: 'ownerlessCargo',
            //             component: () => import('@/views/operate-center/abnormal-data/ownerless-cargo'),
            //             meta: {
            //                 title: 'No_owner_claimed_the_goods',
            //                 isShowing: true
            //             }
            //         },
            //         {
            //             path: '/operateCenter/abnormalData/returnClaim', // 退货认领
            //             name: 'returnClaim',
            //             component: () => import('@/views/operate-center/abnormal-data/return-claim'),
            //             meta: {
            //                 title: 'return_claim',
            //                 isShowing: true
            //             }
            //         }
            //         // {
            //         //     path: '/operateCenter/abnormalData/operationAbnormal',
            //         //     name: 'operationAbnormal',
            //         //     component: () => import('@/views/operate-center/abnormal-data/operation-abnormal'),
            //         //     meta: {
            //         //         title: 'Operation_exception',
            //         //         isShowing: true
            //         //     }
            //         // },
            //         // {
            //         //     path: '/operateCenter/abnormalData/operationAbnormalDetail', // 待更改：异常详情
            //         //     name: 'operationAbnormalDetail',
            //         //     component: () => import('@/views/operate-center/abnormal-data/operation-abnormal-detail'),
            //         //     meta: {
            //         //         title: 'View_details',
            //         //         isShowing: false
            //         //     }
            //         // },
            //     ]
            // },
            {
                path: '/operateCenter/baseSetting', // 基础设置
                name: 'baseSetting',
                component: () => import('@/views/operate-center/base-setting'),
                redirect: '/operateCenter/baseSetting/vehicleManagement',
                meta: {
                    title: 'Basic_setup',
                    icon: baseSettingIcon,
                    isMulti: true,
                },
                children: [
                    {
                        path: '/operateCenter/baseSetting/vehicleManagement',
                        name: 'vehicleManagement',
                        component: () => import('@/views/operate-center/base-setting/vehicle-management'),
                        meta: {
                            title: 'car_management', // 车辆管理
                            isShowing: true
                        }
                    },
                    {
                        path: '/operateCenter/baseSetting/warehouseManagement',
                        name: 'warehouseManagement',
                        component: () => import('@/views/operate-center/base-setting/warehouse-management'),
                        meta: {
                            title: 'location_management', // 库位管理
                            isShowing: true
                        }
                    },
                    {
                        path: '/operateCenter/baseSetting/areaManagement',
                        name: 'areaManagement',
                        component: () => import('@/views/operate-center/base-setting/area-management'),
                        meta: {
                            title: 'Warehouse_details', // 仓库详情
                            isShowing: false,
                            activeTitle: 'warehouseManagement'
                        }
                    },
                    {
                        path: '/operateCenter/baseSetting/subareaManagement',
                        name: 'subareaManagement',
                        component: () => import('@/views/operate-center/base-setting/subarea-management'),
                        meta: {
                            title: 'classification_management', // 分区管理
                            isShowing: false,
                            activeTitle: 'warehouseManagement'
                        }
                    },
                    {
                        path: '/operateCenter/baseSetting/storageDetail',
                        name: 'storageDetail',
                        component: () => import('@/views/operate-center/base-setting/storage-detail'),
                        meta: {
                            title: 'storage_management', // 库位管理
                            isShowing: false,
                            activeTitle: 'warehouseManagement'
                        }
                    },
                ]
            },
        ]
    },
]