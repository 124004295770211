import "core-js/modules/es.array.push.js";
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      uploadPercent: "0",
      preUrl: "",
      preName: "",
      isPre: false,
      headers: {
        authKey: localStorage.getItem("authKey")
      },
      baseURL: process.env.VUE_APP_BASE_URL,
      fileList: proxy.$attrs['file-list'] || []
    });
    onMounted(() => {
      console.log(state.fileList);
    });
    const handleRemove = file => {
      const uploadFiles = state.fileList;
      state.fileList = [];
      uploadFiles.map(item => {
        if (item.uid != file.uid) {
          state.fileList.push({
            ...item
          });
        }
      });
      content.emit("removeSuccess", state.fileList);
    };
    const handlePreview = file => {
      state.preName = file.name;
      state.preUrl = file.src;
      proxy.$attrs.listType === "picture" ? state.isPre = true : state.isPre = false;
      if (proxy.$attrs.listType === "text") {
        proxy.$downloadFile(file.response.data.ossSrc, file.name);
      }
    };
    const handleProgress = file => {
      state.uploadPercent = parseInt(file.percent) - 10 + '%';
      content.emit("isUploadSuccess", false);
    };
    const handleSuccess = (res, uploadFile, uploadFiles) => {
      state.fileList.push({
        name: uploadFile.name,
        src: res.data.src,
        url: res.data.ossSrc,
        uid: uploadFile.uid
      });
      content.emit("uploadSuccess", state.fileList);
      content.emit("isUploadSuccess", true);
    };
    const downloadPic = () => {
      proxy.$downloadFile(state.preUrl, state.preName);
    };
    return {
      ...toRefs(state),
      handlePreview,
      handleRemove,
      downloadPic,
      handleProgress,
      handleSuccess
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "0fc506d9": _ctx.uploadPercent
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;