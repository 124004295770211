import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import operateCenter from './modules/operate-center'
import orderCenter from './modules/order-center'
import enterpriseCenter from './modules/enterprise-center'
import serviceCenter from './modules/service-center'
export const asyncRoutes = [
  ...operateCenter,
  ...orderCenter,
  ...enterpriseCenter,
  ...serviceCenter
]

export const constantRoutes = [
  {
    name: "index",
    path: "/",
    component: () => import("@/views/index"),
    children: []
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/login"),
  },
  {
    hide: true,
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

export default router
