export default {
    login: {
        'Welcome_to_TC_system': "欢迎登录TC系统",
        "Login_account": "登录账号",
        "Please_enter_your_login_account": "请输入登录账号",
        "Login_password": "登录密码",
        "Please_enter_your_login_password": "请输入登录密码",
        "Remember_the_account_password": "记住账号密码",
        "Enterprise_wechat_login": "企业微信登录",
        "Log_in": "登录",
        "logining": "登录中",
        "Login_success": "登录成功",
        "Login_failure": "登录失败",
        "exit": "退出",
        "exiting": "退出中",
        "Exit_successfully": "退出成功",
        "Exit_failure": "退出失败",
        "Welcome_to_login_TC_system": "欢迎登录TC系统",
        "account": "登录账号",
        "Password": "登录密码",
        "Please_enter_login_account": "请输入登录账号",
        "Please_enter_login_password": "请输入登录密码",
        "Log_in_Wecom": "企业微信登录",
        "It_is_on_the_access_road": "登录中",
        "Access_error": "登录失败",

    },
    menu: {
        "WarehouseData": "仓库数据",
        "View_details": "查看详情",
        "Warehousing_management": "入库管理",
        "Out_warehouse_management": "出库管理",
        "Order_management": "订单管理",
        "Pallet_management": "容器管理",
        "Abnormal_data": "异常数据",
        "Warehousing_anomaly": "入库异常",
        "Outgoing_exception": "出库异常",
        "Operation_exception": "操作异常",
        "Vehicle_management": "车辆管理",
        "Order_list": "订单目录",
        "FBM_appointment": "美客多预约",
        "Appointment_status": "预约情况",
        "Storage_location_management": "库位管理",
        "zone_management": "库位管理",
        "warehouse_data": "仓库数据",
        "classification_data": "分区数据",
        "classification_details": "分区详情",
        "pela_details": "托盘详情",
        "cargo_entry_management": "入库管理",
        "cargo_entry_details": "入库详情",
        "Shipment_details": "运单详情",
        "load_output_administration": "出库管理",
        "load_output_details": "出库详情",
        "order_administration": "订单管理",
        "shipment_administration": "货件管理",
        "order_details": "订单详情",
        "abnormal_load_input_situation": "入库异常",
        "abnormal_situation_details": "异常详情",
        "load_output_abnormal_situation": "出库异常",
        "abnormal_driving_situation": "操作异常",
        "container_administration": "容器管理",
        "container_details": "容器详情",
        "location_management": "库位管理",
        "classification_management": "分区管理",
        "platform_management": "卡板管理",
        "car_management": "车辆管理",
        "Mercado_Libre_Reservation": "美客多预约",
        "Reservation_status": "预约情况",
        "Check_the_shipment_number": "货件号查询",
        "Basic_setup": "基础设置",
        "Warehouse_details": "仓库详情",
        "Enterprise_architecture_management": "企业架构管理",
        "System_setting": "系统设置",
        "Department_setting": "部门设置",
        "Employee_account_management": "员工账号管理",
        "Role_rights_management": "角色权限管理",
        "Role_setting": "角色设置",
        "Authority_management": "权限管理",
        "Work_order_management": "工单管理",
        "Add_new_work_order": "新建工单",
        "Work_order_details": "工单详情",
        "Customer_service_Settings": "客服设置",
        "Work_order_type": "工单类型",
        "Problem_type": "问题类型",
        "Drafts": "草稿箱",
        "check": "查看",
        "add": "新建",
        "intercept": "出仓拦截",
        "updateFiles": "更新附件",
        "delete": "删除",
        "cancel": "取消送仓",
        "edit": "编辑",
        "download": "下载",
        "storage_management": "库位管理",
        "No_owner_claimed_the_goods": "无主认领货物",
        "return_claim": "退货认领"
    },
    selectPart: {
        "Operation_center": "操作中心",
        "Order_center": "订单中心",
        "Enterprise_center": "企业中心",
        "Service_center": '客服中心',
    },
    warehouseData: {
        "Current_pieces": "当前件数/件",
        "Current_pieces_of_order/ticket": "当前订单数/票",
        "Total_weight": "总重量",
        "Total_weight/volume": "总容量、体积",
        "Pending_arrival_orders_at_the_warehouse": "待入仓订单",
        "Orders_pending_handling": "待操作订单",
        "abnormal_orders": "异常订单",
        "orders_that_have_been_held_for_a_long_time": "存放过久订单",
        "Total_pieces": "总件数/件",
        "Total_volume": "总体积",
        "Total_order/ticket": "总订单/票",
        "Day": "日",
        "Month": "月",
        "Year": "年",
        "cargo_intake_today/piece": "今日入库/件",
        "Morning": "上午",
        "Afternoon": "下午",
        "cargo_departure_today/piece": "今日出库/件",
        "Handling_today/piece": "今日操作/件",
        "Classification_of_warehouse_area": "仓库分区",
        "Import_data": "数据导入",
        "reservation_space_is_less_than": "存储空间小于",
        "cargo_entry_worker": "入库操作员",
        "Time_of_warehousing": "入库时间",
        "Warehouse": "所在仓库",
        "classification_area": "分区",
        "pallet_number": "卡板号",
        "deposit_time": "存放时长",
        "secondary_shipment_number": "子运单号",
        "secondary_shipping/secondary_outer_carton_label": "子货件号/子唛头",
        "Pieces": "件数/件",
        "Weight": "重量",
        "Number_of_shipment": "运单号",
        "Number_of_entering_warehouse": "入库单号",
        "Number_of_products/tag": "货件号/唛头",
        "choose_classification_area": "分区选择",
        "filter": "筛选",
        "Reset": "重置",
        "Client_name": "客户名称",
        "Salesman": "业务员",
        "deposit_time/day": "存放时长/天",
        "handling": "操作",
        "Check": "查看",
        "Please_enter_content": "请输入内容",
        "No_region_storage_information_was_entered": "未录入区域存储信息",
        "Storage_space_less_than": "存储空间小于",
        "Search_results": "搜索结果",
        "Total": "总件数",
        "Old_shipment_number": "旧货件号",
        "change_order_number": "改单号",
        "region_name": "区域名",
        "status": "状态",
        "Update_time": "更新时间",
        "volume": "体积",
        "Data_export": "数据导出",
        "Already_listed": "已上架",
        "Taken_down": "已下架"
    },
    warehousing: {
        "delivery_number": "运单号",
        "cargo_entry_number": "入库单号",
        "shipping/outer_box_label": "货件号/唛头",
        "state": "状态",
        "Container_pick_up_date": "提柜日期",
        "Container_number": "柜号",
        "SO_number": "SO号",
        "cargo_entry_time": "入库时间",
        "import_data": "数据导入",
        "export_data": "数据导出",
        "filter": "筛选",
        "reboot": "重置",
        "pick_up_order_number/container_number": "提单号/柜号",
        "type_of_transport": "运输类型",
        "related_shipping_quantity/pcs": "关联运单数/条",
        "total_pieces/piece": "总件数/件",
        "total_volume/cubic_meters": "总体积/CBM",
        "total_weight/kg": "总重量/KG",
        "warehouse_receipt_amount/piece": "已入仓件数/件",
        "review": "查看",
        "secondary_shipment_number": "子运单号",
        "secondary_shipping/secondary_outer_carton_label": "子货件号/子唛头",
        "product_parts/parts": "产品数量/件",
        "volume/cubic_meters": "体积/CBM",
        "weight/kg": "重量/KG",
        "have_been_entered": "已入库",
        "total_pieces": "总件数",
        "total_weight": "总重量",
        "total_volume": "总体积",
        "cargo_entry_parts": "已入库件数",
        "pieces_without_charge_entry": "未入库件数",
        "driving": "操作",
        "print_income_sheet": "打印入库单",
        "export_income_sheet": "导出入库单",
        "warehousing": "待入库",
        "Failed_to_get_warehousing_list": "获取入仓列表失败",
        "Transport_type": "运输类型",
        "Failed_to_get_warehousing_details": "获取入仓详情失败",
        "Failed_to_get_waybill_details": "获取运单详情失败",
        "shipping": "货件号",
        "secondary_shipping": "子货件号",
        "location_code/container_code": "库位码/容器码",
        "all": "全部",
        "warehoused": "已出库",
        "exporting": "导出中",
        "Export_successfully": "导出成功",
        "printing": "打印中",
        "Print_successfully": "打印成功",
        "Print_unsuccessfully": "打印失败",
        "Company_logo": "公司标识",
        "Export_unsuccessfully": "导出失败"
    },
    outwarehouse: {
        "delivery_number": "运单号",
        "load_output_number": "出库单号",
        "shipping/outer_box_label": "货件号/唛头",
        "state": "状态",
        "creation_date": "创建日期",
        "cargo_departure_time": "出库时间",
        "related_shipping_quantity/pcs": "关联运单数/条",
        "scan_quantity/pieces": "已扫码件数/件",
        "person_responsible_for_shipping": "送仓负责人",
        "shipping_car_number": "送仓车牌号",
        "secondary_shipment_number": "子运单号",
        "secondary_shipping/secondary_outer_carton_label": "子货件号/子唛头",
        "successful_scan_parts": "已扫码件数",
        "parts_without_scanning": "未扫码件数",
        "download_authorization": "下载授权书",
        "print_output_sheet": "打印出库单",
        "export_output_sheet": "导出出库单",
        "Create_delivery_list": "新建出库单",
        "title": "标题",
        "Total": "总件数",
        "related_shipping_quantity": "关联运单数",
        "successful_number": "已扫码件数",
        "reject_number": "拒收件数",
        "intercept_number": "拦截件数",
        "creator": "创建人",
        "address": "地址",
        "Create_time": "创建时间",
        "Delivery_time": "送仓时间",
        "remark": "备注",
        "driving": "操作",
        "view": "查看",
        "delete": "删除",
        "Are_you_sure_to_delete": "确定删除？",
        "confirm": "确定",
        "cancel": "取消",
        "waiting_for_out_warehouse": "待出库",
        "warehousing": "出库中",
        "warehoused": "已出库",
        "out_warehouse_abnormally": "出库异常",
        "deleting": "删除中",
        "Successfully_delete": "删除成功",
        "Delete_unsuccessfully": "删除失败",
        "Failed_to_get_out_warehouse_list": "获取出库列表失败",
        "Attached_file": "附带文件",
        "Only_support": "仅支持",
        "format": "格式",
        "upload": "上传",
        "save": "保存",
        "saving": "保存中",
        "Save_successfully": "保存成功",
        "Save_unsuccessfully": "保存失败",
        "Intercepted_number_of_out_warehouse": "出仓拦截件数",
        "Scan_list": "扫码列表",
        "batch_out_warehouse": "批量出库",
        "out_warehouse_intercept": "出仓拦截",
        "Are_you_sure_to_out_warehouse?": "确定出库？",
        "File_list": "文件列表",
        "batch_download": "批量下载",
        "take_a_photo_and_send_back": "拍照回传",
        "Download": "下载",
        "close": "关闭",
        "Reject_list": "拒收列表",
        "reject_reason": "拒收原因",
        "filter": "筛选",
        "reset": "重置",
        "edit": "编辑",
        "Download_attachment": "下载附件",
        "Update_attachment": "更新附件",
        "Export_the_reject_list": "导出拒收列表",
        "All": "全部",
        "Scanned": "已扫描",
        "To_be_scanned": "待扫描",
        "rejection": "拒收",
        "Failed_to_get_out_warehouse_details": "获取出库详情失败",
        "Please_check_first": "请先勾选",
        "Batch_delivery_successfully": "批量出库成功",
        "Batch_out_warehouse_unsuccessfully": "批量出库失败",
        "Failed_to_get_fileList": "获取文件列表失败",
        "File_is_empty": "文件为空",
        "Download_successfully": "下载成功",
        "Download_unsuccessfully": "下载失败",
        "Failed_to_get_scan_list": "获取扫码列表失败",
        "The_attached_file_is_empty": "附件文件为空",
        "exporting": "导出中",
        "Export_successfully": "导出成功",
        "Export_unsuccessfully": "导出失败",
        "Print_unsuccessfully": "打印失败",
        "printing": "打印中",
        "Print_successfully": "打印成功",
        "intercept_reason": "拦截原因",
        "intercepting": "出仓拦截中",
        "Intercept_successfully": "出仓拦截成功",
        "Intercept_unsuccessfully": "出仓拦截失败",
        "Edit_reject_reason": "编辑拒收原因",
        "editing": "编辑中",
        "Edit_successfully": "编辑成功",
        "Edit_failure": "编辑失败",
        "Please_upload_the_attached_file": "请上传附带文件",
        "updating": "更新中",
        "Update_successfully": "更新成功",
        "Export_warehouse_orders_in_batches": "批量导出出库单"

    },
    order: {
        "exchange_number": "转单号",
        "cargo_departure_time": "出库时间",
        "cargo_exit_hold": "出货仓库",
        "charge_output_state": "出货状态",
        "type_of_transport": "运输类型",
        "shipping_time": "发出时间",
        "status_of_receiving_products": "收货状态",
        "cargo_access_code": "入库编号",
        "load_exit_code": "出库编号",
        "pick_up_order_number/container_number": "提单号/柜号",
        "time_to_remove_containers": "提柜时间",
        "cargo_entry_time": "入库时间",
        "warehouse_management": "仓库操作",
        "old_reserve_area": "原存放区域",
        "reserve_area_now": "现存放区域",
        "secondary_shipment_number": "子运单号",
        "new_secondary_shipping_number": "新子运单号",
        "shipping_new/label": "新货件号/唛头",
        "length": "长",
        "width": "宽",
        "height": "高",
        "export_sheet": "导出面单",
        "export_outer_box_label": "导出唛头标签",
        "export_corresponding_secondary_shipping_sheet": "导出对应子单号面单",
        "export_corresponding_secondary_shipment/outer_box_label": "导出对应子货件号/唛头",
        "delivery_number": "运单号",
        "shipping/outer_box_label": "货件号/唛头",
        "export_data": "数据导出",
        "filter": "筛选",
        "reboot": "重置",
        "total_pieces/piece": "总件数/件",
        "total_volume/cubic_meters": "总体积/CBM",
        "total_weight/kg": "总重量/KG",
        "state": "状态",
        "handling": "操作",
        "Check": "查看",
        "volume/cubic_meters": "体积/CBM",
        "weight/kg": "重量/KG",
        "total_pieces": "总件数",
        "total_weight": "总重量",
        "total_volume": "总体积",
        "piece": "件",
        "Company_logo": "公司标识",
        "Cargo_status": "货物状态"
    },
    container: {
        "add_new_container": "添加新容器",
        "add": "添加",
        "delivery_number": "运单号",
        "container_code": "容器码",
        "secondary_shipping/secondary_outer_carton_label": "子货件号/子唛头",
        "volume": "容积",
        "edit": "编辑",
        "delete": "删除",
        "close": "关闭",
        "discharge": "下载",
        "Cancel": "取消",
        "confirm_on_delete_container": "确定删除容器",
        "edit_container": "编辑容器",
        "container_name": "容器名称",
        "save": "保存",
        "export_data": "数据导出",
        "filter": "筛选",
        "reboot": "重置",
        "total_pieces": "总件数",
        "related_shipping_quantity/pcs": "关联运单数/条",
        "secondary_shipment_number": "子运单号",
        "cargo_access_code": "入库编号",
        "piece": "件",
        "print_container_code": "打印容器码",
        "select_all": "全选",
        "Container_log": "容器日志"
    },
    abnormalData: {
        "delivery_number": "运单号",
        "driving_type": "操作类型",
        "cargo_entry_time": "入库时间",
        "shipping/outer_box_label": "货件号/唛头",
        "Operating_deadline": "最迟操作时间",
        "pieces_uploaded_without_scanning": "未扫码更新件数",
        "paste_label": "贴标",
        "customers": "客户",
        "Salesman": "业务员",
        "secondary_shipment_number": "子运单号",
        "product_label": "产品标",
        "load_output_number": "出库单号",
        "cargo_departure_time": "出库时间",
        "time_to_remove_containers": "提柜时间",
        "related_shipping_quantity/pcs": "关联运单数/条",
        "Warehouse_output_quantity/pieces": "已出仓件数/件",
        "amount_of_strange": "异常数量",
        "successful_scan_parts": "已扫码件数",
        "parts_without_scanning": "未扫码件数",
        "person_responsible_for_shipping": "送仓负责人",
        "shipping_car_number": "送仓车牌号",
        "secondary_shipping/secondary_outer_carton_label": "子货件号/子唛头",
        "quantity_of_products/pieces": "产品数量/件",
        "cargo_entry_number": "入库单号",
        "warehouse_receipt_amount/piece": "已入仓件数/件",
        "pieces_of_strange": "异常件数",
        "pieces_without_charge_entry": "未入库件数",
        "type_of_transport": "运输类型",
        "pick_up_order_number/container_number": "提单号/柜号",
        "Data_export": "数据导出",
        "filter": "筛选",
        "reset": "重置",
        "piece": "件",
        "Total_volume": "总体积",
        "Total_weight": "总重量",
        "driving": "操作",
        "view": "查看",
        "out_warehouse_number": "出库单号",
        "title": "标题",
        "status": "状态",
        "Total": "总件数",
        "reject_number": "拒收件数",
        "intercept_number": "拦截件数",
        "creator": "创建人",
        "address": "地址",
        "create_time": "创建时间",
        "delivery_time": "送仓时间",
        "remark": "备注",
        "delete": "删除",
        "Are_you_sure_to_delete": "确定删除？",
        "cancel": "取消",
        "waiting_for_out_warehouse": "待出库",
        "warehousing": "出库中",
        "warehoused": "已出库",
        "out_warehouse_abnormally": "出库异常",
        "deleting": "删除中",
        "Successfully_deleted": "删除成功",
        "Failed_to_get_out_warehouse_list": "获取出库列表失败",
        "Deletion_failure": "删除失败",
        "Scan_list": "扫码列表",
        "batch_delivery": "批量出库",
        "out_warehouse_intercept": "出仓拦截",
        "Are_you_sure_to_out_warehouse?": "确定出库？",
        "File_list": "文件列表",
        "batch_download": "批量下载",
        "take_a_photo_and_send_back": "拍照回传",
        "download": "下载",
        "close": "关闭",
        "Reject_list": "拒收列表",
        "download_attachment": "下载附件",
        "edit": "编辑",
        "update_attachment": "更新附件",
        "print_out_warehouse_list": "打印出库单",
        "export_out_warehouse_list": "导出出库单",
        "Export_the_reject_list": "导出拒收列表",
        "all": "全部",
        "Scanned": "已扫描",
        "To_be_scanned": "待扫描",
        "rejection": "拒收",
        "Failed_to_get_out_warehouse_details": "获取出库详情失败",
        "Failed_to_get_scan_list": "获取扫码列表失败",
        "Please_check_first": "请先勾选",
        "Batch_delivery_successfully": "批量出库成功",
        "Batch_out_warehouse_unsuccessfully": "批量出库失败",
        "Failed_to_get_fileList": "获取文件列表失败",
        "File_is_empty": "文件为空",
        "Download_unsuccessfully": "下载失败",
        "Download_successfully": "下载成功",
        "The_attached_file_is_empty": "附件文件为空",
        "exporting": "导出中",
        "Export_successfully": "导出成功",
        "Export_unsuccessfully": "导出失败",
        "intercept_reason": "拦截原因",
        "intercepting": "出仓拦截中",
        "Intercept_successfully": "出仓拦截成功",
        "Edit_reject_reason": "编辑拒收原因",
        "reject_reason": "拒收原因",
        "editing": "编辑中",
        "Edit_successfully": "编辑成功",
        "Edit_failure": "编辑失败",
        "Update_attachment": "更新附件",
        "Please_upload_the_attached_file": "请上传附带文件",
        "Only_xls,_xlsx,_PDF_files_are_supported": "仅支持 xls, xlsx, PDF文件",
        "updating": "更新中",
        "Update_successfully": "更新成功",
        "Intercept_unsuccessfully": "出仓拦截失败",
        "Failed_to_get_warehousing_list": "获取入仓列表失败",
        "warehoused_number": "已入库件数",
        "volume": "体积/CBM",
        "weight": "重量/KG",
        "Failed_to_get_warehousing_details": "获取入仓详情失败",
        "confirm": "确定",
        "save": "保存",
        "Intercepted_number_of_out_warehouse": "出仓拦截件数",
        "Warehouse_area": "仓库区域",
        "uploader": "上传人",
        "Upload_time": "上传时间",
        "picture": "图片",
        "TC_sub-order_number": "TC子单号",
        "secondary_shipping": "子货件号",
        "modifier": "修改人",
        "modifier_time": "修改时间",
        "edit_order_number": "编辑单号",
        "please_enter_the_sub-order_number": "请输入子单号",
        "please_enter_the_secondary_shipping": "请输入子货件号",


    },
    baseSetting: {
        "add": "添加",
        "brand": "品牌",
        "boss/responsible_person": "负责人",
        "administrator": "监管人",
        "edit": "编辑",
        "Delete": "删除",
        "Sure_to_delete_car_information?": "确定删除车辆信息？",
        "Confirm": "确定",
        "Cancel": "取消",
        "add_car_info": "添加车辆信息",
        "car_number": "车牌号",
        "Car_type": "车辆类型",
        "Purchased_time": "购入时间",
        "all_people/everyone": "所有人",
        "regulator/administrator": "监管员",
        "save": "保存",
        "edit_car_info": "编辑车辆信息",
        "contact": "联系人",
        "phone_contact": "联系电话",
        "detailed_address": "详细地址",
        "add_classification_area": "添加分区",
        "secondary_sorting_quantity/pieces": "子分区数量/个",
        "number_of_pallets/pieces": "卡板数量/个",
        "maximum_capacity/cubic_meters": "最大容量/CBM",
        "sure_to_delete_area_F?": "确定删除F 区？",
        "classification_name": "分区名称",
        "maximum_capacity": "最大容量",
        "edit_warehouse_information": "仓库信息编辑",
        "warehouse_name": "仓库名称",
        "secondary_classification_area": "子分区",
        "add_secondary_sort_area": "添加子分区",
        "location_code": "库位码",
        "capacity_now": "当前容量",
        "Carboard": "卡板",
        "Add_carboard": "添加卡板",
        "Carboard_code": "卡板码",
        "Edit_carboard": "编辑卡板",
        "Carboard_name": "卡板名称",
        "edit_secondary_sort_area": "编辑子分区",
        "secondary_sort_area_name": "子分区名称",
        "close": "关闭",
        "download": "下载",
        "Total_number_of_pieces": "总件数",
        "shipping": "货件号",
        "delivery_number": "运单号",
        "secondary_shipment_number": "子运单号",
        "sure_to_delete": "确认删除",
        "area": "区",
        "Number_of_subarea": "分区数量/个",
        "Edit_subarea": "编辑分区",
        "Old_shipment_number": "旧货件号",
        "scanning_time": "扫码时间",
        "shelf": "货架",
        "Add_shelf": "添加货架",
        "Export_the_required_location_code_size": "导出所需的库位码尺寸",
        "Export": "导出",
        "Number_of_layers": "层数",
        "Add_layers": "添加层数",
        "location": "库位",
        "Add_location": "添加库位",
        "Failed_to_get_the_warehouse_shelf_list": "获取仓库货架列表失败",
        "Failed_to_get_the_layer_number_of_warehouse_list": "获取仓库层数列表失败",
        "Failed_to_get_the_warehouse_location_list": "获取仓库库位列表失败",
        "print": "打印",
        "Select_All": "全选中",
        "All_complete": "全选完成",
        "Please_select_the_print_item_first": "请先勾选打印项",

    },
    deliveryReservation: {
        "Daily_Reservation": "每日预约",
        "Last_month": "上个月",
        "today": "今天",
        "Next_month": "下个月",
        "Reservation_status": "预约情况",
        "piece": "件",
        "Daily_delivery": "每日送仓",
        "Delivery_instructions": "送仓须知",
        "Reservation_time": "预约时间",
        "Delivery_time": "送仓时间",
        "Shipment_number/shipping_mark": "货件号/唛头",
        "pieces": "件数",
        "Cancel_delivery": "取消送仓",
        "Confirm_whether_to_cancel_delivery": "是否确认取消送仓",
        "Successfully_cancel_delivery": "取消送仓成功",
        "Failed_to_cancel_delivery": "取消送仓失败",
        "Failed_to_get_data": "获取数据失败",
        "export": "导出",
        "exporting": "导出中",
        "Export_successfully": "导出成功",
        "Export_unsuccessfully": "导出失败",
        "delivery_number": "运单号",
        "shipping/outer_box_label": "货件号/唛头",
        "load_output_number": "出库单号",
        "filter": "筛选",
        "reset": "重置",
        "shipping": "货件号",
        "driving": "操作",
        "confirm": "确定",
        "cancel": "取消",

    },
    workorder: {
        "Work_order_number": "工单号",
        "Associated_order_number": "关联单号",
        "Operating_state": "操作状态",
        "work_order_node": "工单节点",
        "Work_order_type": "工单类型",
        "Problem_type": "问题类型",
        "Creation_time": "创建时间",
        "creator": "创建人",
        "Processing_department": "处理部门",
        "filter": "筛选",
        "reset": "重置",
        "customer_code": "客户代码",
        "Current_processing_department": "当前处理部门",
        "Current_processor": "当前处理人",
        "Associated_work_order": "关联工单",
        "Current_node_time": "当前节点耗时",
        "Total_time_spent": "总耗时",
        "Claim_time": "认领时间",
        "Prescription_of_claim": "认领时效",
        "Operating_time": "操作时间",
        "Description": "描述",
        "remark": "备注",
        "Latest_communication_content": "最新沟通内容",
        "view": "查看",
        "All": "全部",
        "Wait_to_be_done": "待办",
        "Have_done": "已办",
        "My_creation": "我的创建",
        "Copy_me": "抄送我的",
        "Withdrawn": "已撤回",
        "rejected": "已驳回",
        "submitted": "已提交",
        "finished": "已完结",
        "Obsolete": "已废弃",
        "Have_already_been_created": "已创建",
        "In_process": "处理中",
        "Failed_to_get_a_CC": "获取抄送失败",
        "Failed_to_get_the_work_order_type_list": "获取工单类型列表失败",
        "Failed_to_get_the_problem_type_list": "获取问题类型列表失败",
        "Failed_to_get_the_department_list": "获取部门列表失败",
        "Failed_to_get_the_employee_list": "获取员工列表失败",
        "Failed_to_get_the_work_order_list": "获取工单列表失败",
        "Failed_to_change_cc_status": "抄送状态变更失败",
        "Basic_information": "基础信息",
        "View_the_change_mark_requirements": "查看换标要求",
        "Attached_file": "附带文件",
        "Attached_picture": "附带图片",
        "Processing_information": "处理信息",
        "Whether_to_view": "是否查看",
        "Owning_warehouse": "所属仓库",
        "Communication_information": "沟通信息",
        "Communication_reply": "沟通回复",
        "issuer": "发出人",
        "Carbon_copy_recipient": "抄送人",
        "Communication_content": "沟通内容",
        "Claim_the_work_order": "认领工单",
        "Processing_work_order": "处理工单",
        "Work_order_processing_log": "工单处理日志",
        "unfold": "展开",
        "handle": "处理",
        "Pack_up": "收起",
        "processor": "处理人",
        "Check_the_time": "查看时间",
        "Node_time_consuming": "节点耗时",
        "Processing_remarks": "处理备注",
        "Are_you_sure_you_want_to_claim_a_work_order?": "确定要认领工单？",
        "confirm": "确定",
        "Cancel": "取消",
        "Are_you_sure_you_want_to_complete_a_work_order?": "确定要完结工单？",
        "source": "来源",
        "Whether_internal_work_order": "是否内部工单",
        "initiator": "发起人",
        "Commit_processing": "提交处理",
        "Finished_work_order": "完结工单",
        "Withdrawal_processing": "撤回处理",
        "Scrap_ticket": "废弃工单",
        "Failed_to_get_domain_name": "获取域名失败",
        "Failed_to_get_details": "获取详情失败",
        "Failed_to_get_chat_list": "获取聊天列表失败",
        "Failed_to_get_the_log_list": "获取日志列表失败",
        "Reason_for_withdrawal": "撤回原因",
        "Cause_of_rejection": "驳回原因",
        "Reason_for_abandonment": "废弃原因",
        "Claiming": "认领中",
        "Claim_successfully": "认领成功",
        "Failed_to_claim_the_work_order": "认领工单失败",
        "In_completion": "完结中",
        "Complete_success": "完结成功",
        "Failed_to_complete_the_work_order": "完结工单失败",
        "Number_of_packages": "件数",
        "Shipping_slip_number": "运单子单号",
        "Old_shipment_number": "旧货件号",
        "Shipping_mark": "唛头",
        "New_shipment_number": "新货件号",
        "Old_SKU_logo": "旧SKU标",
        "New_SKU_logo": "新SKU标",
        "Please_fill_in": "请填写",
        "required": "必填",
        "Picture_upload": "图片上传",
        "Only_support": "仅支持",
        "isoformat": "等图片格式",
        "Being_submitted": "提交中",
        "Submit_successfully": "提交成功",
        "Commit_failure": "提交失败",
        "Processing_result": "处理结果",
        "Failed_to_obtain_the_service_provider_list": "获取服务商列表失败",

    },
    error: {
        "Sorry_the_page_you_are_looking_for_is_missing": "抱歉，您要访问的页面丢失了",
        "To_check_whether_the_URL_you_entered_is_correct_please_click_the_button_below_to_return_to_the_home_page": "检查您输入的网址是否正确，请点击以下按钮返回首页",
        "Back": "返回"
    },
    other: {
        "batch_intercept": "批量拦截",
        "view_excel_content": "Excel内容查看",
        "tc_order_number": "TC单号",
        "tc_order_id": "TC编号",
        "Shipments_sent_to_Full_or_FBA": "送仓的货件",
        "change_mark": "换标",
        "hour": "小时",
        "Export_location_information_(trial)": "导出库位信息(试用)",
        "have_joined_downlod_queue": "已加入下载队列",
        "Edit_information": "编辑信息",
        "Confirm_the_changes?": "确认修改？",
        "Please_enter_search_criteria": "请输入搜索条件",
        "search": "搜索",
        "return_claim": "退货认领",
        "return_code": "退货码",
        "Shipment_number": "货件编号",
        "export_multi_subarea_outwarehouse_order_list": "导出出库单多分区",
        "secondary_shipment/outer_box_label": "子货件号/唛头",
        "pcs": "条",
        "Failed_to_get": "获取失败",
        "Please_enter_the_license_plate_number": "请输入车牌号",
        "Please_select_the_purchase_time": "请选择购入时间",
        "Are_you_sure_to_add_another_layer_to_this_shelf?": "确认在该货架添加一层？",
        "Are_you_sure_to_add_a_storage_location_on_this_shelf?": "确认在该货架该层数添加一个库位？",
        "Are_you_sure_to_delete_this_shelf?": "确认删除该货架？",
        "Are_you_sure_to_delete_the_maximum_number_of_shelves?": "确认删除该货架最高层层数？",
        "Are_you_sure_to_delete_this_storage_location?": "确认删除该库位？",
        "Shelf_name": "货架名称",
        "the_number_of_columns_of_location": "库位列数",
        "single": "单个",
        "batch": "批量",
        "Add_a_new_container": "单个添加新容器",
        "Please_enter_the_container_code": "请输入容器码",
        "The_container_code_is_too_long_Please_re_enter_it": "容器码过长,请重新输入",
        "Add_new_containers_in_batches": "批量添加新容器",
        "quantity": "数量",
        "Please_enter_quantity": "请输入数量",
        "warehouse": "仓库",
        "uploading": "上传中",
        "upload_successfully": "上传成功",
        "out_warehouse": "出仓",
        "Sure_out_warehouse?": "确定出仓？",
        "Dispatching": "出仓中",
        "Successfully_dispatched_from_storage": "出仓成功",
        "Dispatched_from_the_warehouse_unsuccessfully": "出仓失败",
        "Reject_the_ticket": "驳回工单",
        "If_final_processing_is_complete,_select_the_processor_to_make_the_response_(required)": "若最终处理完成，则选择发起人反馈情况（必填）",
        "Interior": "内部",
        "Outside": "外部",

    },
    shipmentno: {
        "Current_shipment_number/ticket": "当前货件数/票",
        "Generate_barcode": "生成条形码",
        "generate": "生成",
        "New/old_shipment_number": "新/旧货件号",
        "First_operator": "第一次操作员",
        "First_operation_time": "第一次操作时间",
        "Current_region": "当前区域",
        "Single_generated_barcode": "单个生成条形码",
        "Please_enter_the_shipment_number/shipping_mark,_such_as_123456/1": "请输入货件号/唛头，如：123456/1",
        "Please_enter_the_shipment_number/shipping_mark": "请输入货件号/唛头",
        "Batch_barcode_generation": "批量生成条形码",
        "Please_enter": "请输入",
        "Start_number": "开始编号",
        "End_number": "结束编号",
        "Mark_separator": "唛头分隔符",
        "If_the_number_is_set_to_1-10,_it_will_be_generated_in_the_form_of_12345-1_to_12345-10": "如果编号填写的为1-10，则将以“12345-1~12345-10”这种形式生成",
        "Please_enter_the_start_number": "请输入开始编号",
        "Please_enter_the_end_number": "请输入结束编号",
    }
}