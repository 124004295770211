import { createStore } from 'vuex'
import user from './modules/user'
import systemSetting from "./modules/system-setting"
import serviceCenter from "./modules/service-center"
export default createStore({
  modules: {
    user,
    systemSetting,
    serviceCenter
  }
})
