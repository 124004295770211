const serviceCenter = {
    namespaced: true,
    state: {
       copyMine:false
    },
    getters: {
    },
    mutations: {
        setCopyMine(state,data){
            state.copyMine = data
        }
    },
    actions: {

    }
}

export default serviceCenter