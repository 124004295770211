// 路由meta的title项 必须为 该多语言对应字段名
export default [
    {
        name: 'orderCenter',
        path: '/orderCenter',
        component: () => import('@/views/order-center'),
        children: [
            {
                name: 'orderList',
                path: '/orderCenterIndex',
                component: () => import('@/views/order-center'),
                redirect: '/orderCenter/orderList/index',
                meta: {
                    title: 'Order_list',
                    icon: '',
                    isMulti: false,
                },
                children: [
                    {
                        name: 'orderListIndex',
                        path: '/orderCenter/orderList/index',
                        component: () => import('@/views/order-center/order-list/order-list-index'),
                        meta: {
                            title: 'Order_list',
                            isShowing: true
                        }
                    }
                ]
            }
        ]
    }
]