import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-09c9243f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "select"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_option_group = _resolveComponent("el-option-group");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$attrs.group ? (_openBlock(), _createBlock(_component_el_select, _mergeProps({
    key: 0,
    modelValue: $setup.keyWord,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.keyWord = $event),
    placeholder: "请选择"
  }, _ctx.$attrs, {
    teleported: false
  }), {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$attrs.options, group => {
      return _openBlock(), _createBlock(_component_el_option_group, {
        key: group.label,
        label: group.label
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 2
      }, 1032, ["label"]);
    }), 128))]),
    _: 1
  }, 16, ["modelValue"])) : (_openBlock(), _createBlock(_component_el_select, _mergeProps({
    key: 1,
    modelValue: $setup.keyWord,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.keyWord = $event)
  }, _ctx.$attrs, {
    teleported: false,
    "remote-method": _ctx.$attrs.remoteMethod
  }), {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$attrs.options, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: item.label,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 16, ["modelValue", "remote-method"]))]);
}