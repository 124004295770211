import { ElConfigProvider } from 'element-plus';
import { watch, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import zh from 'element-plus/lib/locale/lang/zh-cn';
import en from 'element-plus/lib/locale/lang/en';
import es from 'element-plus/lib/locale/lang/es';
export default {
  components: {
    ElConfigProvider
  },
  setup() {
    const store = useStore();
    const state = reactive({
      locale: zh
    });
    watch(() => store.state.user.localLanguage, val => {
      state.locale = val == 'zh' ? zh : val == 'en' ? en : es;
    }, {
      immediate: true
    });
    return {
      ...toRefs(state)
    };
  }
};