import axios from "@/utils/axios/axios";

// 登录
export function userLoginAPI(data) {
    return axios({
        url: 'mx/v1/admin/Login/login',
        method: 'POST',
        data
    })
}

// 退出登录
export function logoutAPI(data) {
    return axios({
        url: 'mx/v1/admin/login/logout',
        method: 'POST',
        data
    })
}

// 切换多语言
export function updateLanguageAPI(data) {
    return axios({
        url: 'mx/v1/admin/user/updateLanguage',
        method: 'POST',
        data
    })
}

// 公司仓库列表
export function privateWarehouseListAPI(data) {
    return axios({
        url: 'mx/v1/operation/Category/privateWarehouseList',
        method: 'POST',
        data
    })
}

// 运输类型
export function transportTypeAPI(data) {
    return axios({
        url: 'mx/v1/operation/Category/transportType',
        method: 'POST',
        data
    })
}
// 获取部门分类列表
export function getStructureTypeListAPI(data) {
    return axios({
        url: 'mx/v1/admin/auth/structure/getStructureTypeList',
        method: 'post',
        data
    })
}
// 获取负责人列表
export function getUserNameListAPI(data) {
    return axios({
        url: 'mx/v1/admin/auth/user/getUserNameList',
        method: 'post',
        data
    })
}
// 获取部门列表
export function getDepartmentListAPI(data) {
    return axios({
        url: 'mx/v1/admin/auth/structure/getList',
        method: 'post',
        data
    })
}
// 获取权限列表
export function getUserAllRuleTreeListAPI(data) {
    return axios({
        url: 'mx/v1/admin/auth/rule/getUserAllRuleTreeList',
        method: 'post',
        data
    })
}
// 获取按钮权限
export function getUserAllButtonRuleKeyListAPI(data) {
    return axios({
        url: 'mx/v1/admin/auth/rule/getUserAllButtonRuleKeyList',
        method: 'post',
        data
    })
}
// oss域名
export function getOssHttpSrcAPI(data) {
    return axios({
        url: 'mx/v1/HttpSrc/ossHttpSrc',
        method: 'post',
        data
    })
}