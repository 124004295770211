import { computed, toRefs, reactive } from 'vue';
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 14
    },
    pageSizes: {
      type: Array,
      default: () => [14, 28, 56, 112]
    }
  },
  setup(props, content) {
    const state = reactive({
      nowPage: props.currentPage
    });
    const page = computed(() => {
      return props.currentPage;
    });
    function handleCurrentChange(val) {
      state.nowPage = val;
      content.emit('handleCurrentChange', val);
    }
    function handleSizeChange(val) {
      content.emit('handleSizeChange', val);
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      page,
      ...toRefs(state)
    };
  }
};