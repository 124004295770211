import { reactive, toRefs, getCurrentInstance, computed } from 'vue';
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      width: proxy.$attrs.width ? proxy.$px2rem(proxy.$attrs.width) : proxy.$px2rem('274px'),
      height: proxy.$attrs.height ? proxy.$px2rem(proxy.$attrs.height) : proxy.$px2rem('30px')
    });
    const keyWord = computed({
      get() {
        return proxy.$attrs.value;
      },
      set(newVal) {
        content.emit("changeValue", newVal);
      }
    });
    // 限制只能选择今天及之前
    const disabledDate = time => {
      return time.getTime() > Date.now() - 8.64e6;
    };
    return {
      keyWord,
      disabledDate,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "cc7a7e32": _ctx.height,
    "164f0606": _ctx.width
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;