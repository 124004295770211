import { watch } from 'vue'
import { computed } from "vue";
import { asyncRoutes } from './index'
import router from './index'
import store from '@/store'
import NProgress from "nprogress";
import 'nprogress/nprogress.css'
import TcMessage from '@/utils/common/tc-message'
import { getUserAllRuleTreeListAPI, getUserAllButtonRuleKeyListAPI } from "@/api/user/user";
let registerRouteFresh = true //防止刷新丢失
let routerTreeData = null
const userRouters = computed(() => store.state.user.userRouters) //用户路由

// 获取用户权限树列表
const getUserAllRuleTreeList = async () => {
    try {
        routerTreeData = await getUserAllRuleTreeListAPI({});
        store.commit('user/addAuthCenters', routerTreeData)
        if (routerTreeData.length == 0) {
            TcMessage({ type: 'error', text: '该账号无权限' });
            localStorage.clear()
            return
        }
        // 修改选中的中心，防止选中的中心权限失去无法加载页面
        let centerFlag = routerTreeData.some(item => {
            return localStorage.getItem("selectPart") === item.key
        })
        if (!centerFlag) {
            let selectPart = routerTreeData[0].key
            let nowPath = routerTreeData[0].children[0].children[0].route
            localStorage.setItem("selectedCenter", selectPart)
            store.commit('user/changeSelectPart', selectPart)
            localStorage.setItem("nowPath", nowPath)
        }
    } catch (error) {
        error.error ? TcMessage({ type: 'error', text: error.error }) : console.log(error);
    }
};

// 获取按钮权限
const getUserBtnsAuth = async () => {
    try {
        let res = await getUserAllButtonRuleKeyListAPI({});
        store.commit('user/addUserBtns', res)
    } catch (error) {
        error.error ? TcMessage({ type: 'error', text: error.error }) : console.log(error);
    }
};

// 动态添加路由
const compareChildren = (allData, authData) => {
    allData.forEach(allItem => {
        authData.forEach(authItem => {
            if (allItem.name === authItem.key) {
                allItem.meta["auth"] = true
                if (allItem.children && authItem.children) {
                    return compareChildren(allItem.children, authItem.children)
                }
            }
        })
    })
}

const addRouters = async () => {
    await getUserAllRuleTreeList()
    asyncRoutes.forEach(centerItem => {
        routerTreeData.forEach(treeCenter => {
            if (centerItem.name === treeCenter.key) {
                // 进入child比较
                centerItem['auth'] = true
                compareChildren(centerItem.children, treeCenter.children)
            }
        })
    })
}

// 监听selectPart的变化，添加对应模块路由
watch(() => store.state.user.selectPart, () => {
    asyncRoutes.forEach(item => {
        if (item.name == store.state.user.selectPart) {
            store.commit("user/clearUserRouters")
            item.children.forEach(childItem => {
                router.addRoute('index', childItem)
                store.commit('user/addUserRouters', childItem)
            })
        }
    })
})

// 获取是否缓存
const addRouteCache = async (routeData) => {
    routeData.forEach(item => {
        if (item.children && item.children.length) {
            store.commit('user/addRouteCache', item.name)
            return addRouteCache(item.children)
        }
    })
}

// 路由守卫
router.beforeEach(async (to, from, next) => {
    const authKey = localStorage.getItem("authKey")
    NProgress.start()
    if (authKey) {
        if (to.path === '/login') {
            next("/")
        } else {
            if (to.path === "/") {
                localStorage.removeItem("nowPath")
            }
            if (registerRouteFresh) {
                await addRouters()
                await getUserBtnsAuth()
                // TODO : 路由动态添加
                let userAllRoutes = []
                // 获取一级
                asyncRoutes.forEach(item => {
                    item.auth ? userAllRoutes.push(item) : ""
                })
                userAllRoutes.forEach(item => {
                    let userSecondRoutes = []
                    item.children.forEach(childItem => {
                        let userThirdRoutes = []
                        if (childItem.meta.auth) {
                            userSecondRoutes.push(childItem)
                            childItem.children.forEach(thirdItem => {
                                if (thirdItem.meta.auth) {
                                    userThirdRoutes.push(thirdItem)
                                }
                            })
                        }
                        // 获取三级
                        childItem.children = userThirdRoutes
                    })
                    // 获取二级
                    item.children = userSecondRoutes
                })
                userAllRoutes.forEach(item => {
                    item.children.forEach(childItem => {
                        // 刷新时，路由导航添加固定选择
                        item.name == store.state.user.selectPart ? store.commit('user/addUserRouters', childItem) : ""
                        router.addRoute('index', childItem)
                    })
                })
                await addRouteCache(asyncRoutes)
                // 触发watch
                store.commit('user/changeSelectPart', "")
                registerRouteFresh = false;
                next({
                    path: localStorage.getItem("nowPath") || userRouters.value[0].path,
                    query: to.query
                })
            } else {
                next()
            }
        }
    } else {
        if (to.path === '/login') {
            registerRouteFresh = true;
            next()
            return
        }
        next('/login')

    }
    NProgress.done()
})