import { reactive, toRefs, getCurrentInstance, computed, onMounted } from 'vue';
// :remote-method="$attrs.remoteMethod" // 远程搜索
// $attrs.group 分组
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      width: proxy.$attrs.width ? proxy.$px2rem(proxy.$attrs.width) : proxy.$px2rem('274px'),
      height: proxy.$attrs.multiple ? 'auto' : proxy.$attrs.height ? proxy.$px2rem(proxy.$attrs.height) : proxy.$px2rem('30px'),
      borderRadius: proxy.$attrs.borderRadius ? proxy.$px2rem(proxy.$attrs.borderRadius) : proxy.$px2rem('8px'),
      popperHeight: proxy.$attrs.popperHeight ? proxy.$px2rem(proxy.$attrs.popperHeight) : proxy.$px2rem('120px')
    });
    const keyWord = computed({
      get() {
        return proxy.$attrs.value;
      },
      set(newVal) {
        // 单组
        if (!proxy.$attrs.group) {
          // 单选
          if (!proxy.$attrs.multiple) {
            const selected = proxy.$attrs.options.find(item => {
              return item.value === newVal;
            }) || newVal;
            content.emit("changeValue", selected);
          } else {
            // 多选
            content.emit("changeValue", newVal);
          }
        } else {
          // 分组单选
          if (!proxy.$attrs.multiple) {
            for (var i = 0; i < proxy.$attrs.options.length; i++) {
              const selected = proxy.$attrs.options[i].options.find(keyWord => {
                return keyWord.value == newVal;
              });
              if (selected) return content.emit("changeValue", selected);
            }
          } else {
            // 多选
            content.emit("changeValue", newVal);
          }
        }
      }
    });
    return {
      keyWord,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "0f62853c": _ctx.width,
    "79c1af1e": _ctx.height,
    "6f5173a8": _ctx.borderRadius,
    "56561586": _ctx.popperHeight,
    "64018b46": _ctx.filterable ? ' ' : '-1px ',
    "b123c974": _ctx.filterable ? '10px' : ' '
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;