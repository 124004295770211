import WorkSheetManageIcon from "@/assets/images/service-center/nav-work-sheet.svg"
import ServiceSettingIcon from "@/assets/images/service-center/nav-service-setting.svg"
import DraftsIcon from "@/assets/images/service-center/nav-drafts.svg"
/**
 * @param {Boolean} isMulti 是否有多级导航，仅父路由有
 * @param {Boolean} isShowing 是否显示  
 */
export default [
    {
        name: "serviceCenter",
        path: "/serviceCenter",
        component: () => import("@/views/service-center"),
        children: [
            // 工单管理
            {
                name: "workSheetManage",
                path: "/serviceCenterIndex",
                component: () => import("@/views/service-center/work-sheet-manage"),
                redirect: "/serviceCenter/workSheetManage/index",
                meta: {
                    title: "Work_order_management",
                    icon: WorkSheetManageIcon,
                    isMulti: false,
                    isShowing: true
                },
                children: [
                    {
                        name: "workSheetManageIndex",
                        path: "/serviceCenter/workSheetManage/index",
                        component: () => import("@/views/service-center/work-sheet-manage/work-sheet-manage-index"),
                        meta: {
                            title: "Work_order_management",
                            isShowing: false
                        }
                    },
                    {
                        name: "addWorkSheet",
                        path: "/serviceCenter/workSheetManage/addWorkSheet",
                        component: () => import("@/views/service-center/work-sheet-manage/add-work-sheet"),
                        meta: {
                            title: "Add_new_work_order",
                            isShowing: false
                        }
                    },
                    {
                        name: "workSheetDetail",
                        path: "/serviceCenter/workSheetManage/workSheetDetail",
                        component: () => import("@/views/service-center/work-sheet-manage/work-sheet-detail"),
                        meta: {
                            title: "Work_order_details",
                            isShowing: false
                        }
                    },
                ]
            },
            // 客服设置
            {
                name: "serviceSetting",
                path: "/serviceCenter/serviceSetting",
                component: () => import("@/views/service-center/service-setting"),
                redirect: "/serviceCenter/serviceSetting/index",
                meta: {
                    title: "Customer_service_Settings",
                    icon: ServiceSettingIcon,
                    isMulti: true,
                    isShowing: true
                },
                children: [
                    {
                        name: "workSheetType",
                        path: "/serviceCenter/serviceSetting/workSheetType",
                        component: () => import("@/views/service-center/service-setting/work-sheet-type"),
                        meta: {
                            title: "Work_order_type",
                            isShowing: true
                        }
                    },
                    {
                        name: "problemType",
                        path: "/serviceCenter/problemType/index",
                        component: () => import("@/views/service-center/service-setting/problem-type"),
                        meta: {
                            title: "Problem_type",
                            isShowing: true
                        }
                    },
                ]
            },
            // 草稿箱
            {
                name: "drafts",
                path: "/serviceCenter/drafts",
                component: () => import("@/views/service-center/drafts"),
                redirect: "/serviceCenter/drafts/index",
                meta: {
                    title: "Drafts",
                    icon: DraftsIcon,
                    isMulti: false,
                    isShowing: true
                },
                children: [
                    {
                        name: "draftsIndex",
                        path: "/serviceCenter/drafts/index",
                        component: () => import("@/views/service-center/drafts/drafts-index"),
                        meta: {
                            title: "Drafts",
                            isShowing: false
                        }
                    }
                ]
            },
    
        ]
    }

]