import { reactive, toRefs, getCurrentInstance, computed } from 'vue';
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      width: proxy.$attrs.width ? proxy.$px2rem(proxy.$attrs.width) : proxy.$px2rem('274px'),
      height: proxy.$attrs.height ? proxy.$px2rem(proxy.$attrs.height) : proxy.$px2rem('24px'),
      borderRadius: proxy.$attrs.borderRadius ? proxy.$px2rem(proxy.$attrs.borderRadius) : proxy.$px2rem('8px'),
      popperHeight: proxy.$attrs.popperHeight ? proxy.$px2rem(proxy.$attrs.popperHeight) : proxy.$px2rem('120px')
    });
    const keyWord = computed({
      get() {
        return proxy.$attrs.value;
      },
      set(newVal) {
        content.emit("changeValue", newVal);
      }
    });
    return {
      keyWord,
      ...toRefs(state)
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "13f906a2": _ctx.height,
    "70a8c62a": _ctx.width,
    "7e43adce": _ctx.borderRadius,
    "2661aaae": _ctx.popperHeight,
    "4685160e": _ctx.filterable ? ' ' : '-1px ',
    "8854f516": _ctx.filterable ? '10px' : ' '
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;